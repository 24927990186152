import { required, maxLength } from 'vuelidate/lib/validators';
import { onlyAlphanumeric5, validationSeal } from '@/_validations/validacionEspeciales'
import { DateFormater } from "@/_helpers/funciones";

export default (VerifySeal,  PreviousDate, LaterDate, DispatchDate, EventService, fromDay, toDay, misTime) => {
    let seal1Valid = VerifySeal.ConditionSeal1  ? required : true;
    let ConditionSeal1Valid = { required } ;
    let seal2Valid = VerifySeal.ConditionSeal2  ? required : true;
    let ConditionSeal2Valid = { required };
    let seal3Valid = VerifySeal.ConditionSeal3 ? required : true;
    let ConditionSeal3Valid = { required };
    let seal4Valid = VerifySeal.ConditionSeal4 ? required : true;
    let ConditionSeal4Valid = { required } ;
    let EventServiceValid = EventService ? { required } : true;
    let EventReason =  { required };

    let from = misTime ?
    { required, TimeValidate(value){
        return(DateFormater.formatDateTimeWithoutSlash(value) >= PreviousDate &&
            DateFormater.formatDateTimeWithoutSlash(value) <= LaterDate && 
            DateFormater.formatDateTimeWithoutSlash(value) < DispatchDate 
        )
    },
    RangeDate(value){
        return toDay ? DateFormater.formatDateTimeWithoutSlash(value) <  DateFormater.formatDateTimeWithoutSlash(toDay) : true;
    } } : true;

    return {
        YardName: {},
        EventReasonId: EventReason,
        EventServiceId: EventServiceValid,
        MachineId: {},
        TransactionDate: {},
        fromDay: from,
        toDay: {},
        Observation: { onlyAlphanumeric5 },
        Seal: {
            Seal1: {
                Seal: { seal1Valid, maxLength: maxLength(20), validationSeal },
                ConditionSeal: ConditionSeal1Valid,
                SealAssignmentId: {},
                ObservationSeal: { onlyAlphanumeric5 },
                SealDocumentJson: {},
            },
            Seal2: {
                Seal: { seal2Valid, maxLength: maxLength(20), validationSeal },
                ConditionSeal: ConditionSeal2Valid,
                SealAssignmentId: {},
                ObservationSeal: { onlyAlphanumeric5 },
                SealDocumentJson: {},
            },
            Seal3: {
                Seal: { seal3Valid, maxLength: maxLength(20), validationSeal },
                ConditionSeal: ConditionSeal3Valid,
                SealAssignmentId: {},
                ObservationSeal: { onlyAlphanumeric5 },
                SealDocumentJson: {},
            },
            Seal4: {
                Seal: { seal4Valid, maxLength: maxLength(20), validationSeal },
                ConditionSeal: ConditionSeal4Valid,
                SealAssignmentId: {},
                ObservationSeal: { onlyAlphanumeric5 },
                SealDocumentJson: {},
            },
        },
    }
}