<template>
    <div>
        <CCollapse :show="activeItem(1)">
            <Desconsolidated/>
        </CCollapse>
        <CCollapse :show="activeItem(2)">
            <Emptied/>
        </CCollapse>
        <CCollapse :show="activeItem(3)">
            <ReceptionHouse/>
        </CCollapse>
        <!--
        <CCollapse :show="activeItem(2)">
            <Implements/>
        </CCollapse>
        <CCollapse :show="activeItem(3)">
            <Trasegado/>
        </CCollapse>
        -->
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import Desconsolidated from './desconsolidated/desconsolidated-index';
    import Emptied from './emptied/emptied-index';
    import Implements from './implements/implements-index';
    import Trasegado from './trasegado/trasegado-index';
    import ReceptionHouse from './pendingHouse/housesList';
    //methods
    function activeItem(item) {
        return this.dropItemEvent === item;
    }
    
    export default {
        name:"events-index",
        methods:{
            activeItem,
        },
        components:{
            Desconsolidated,
            Emptied,
            Implements,
            Trasegado,
            ReceptionHouse
        },
        computed:{
            ...mapState({
                tabIndex: state => state.yard-management.tabMovementIndex,
                dropItemEvent: state => state.yardManagement.dropItemEvent,
            })
        },
        watch:{
            //tabIndex
        }
    }
</script>